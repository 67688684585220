<template>
  <div class="veille contain">
    <h1 class="text-3xl font-bold text-gray-600 mb-12 text-center">Ma veille technologique</h1>
    <div class="container w-3/4" style="margin: auto">

      <p class="text-justify text-xl">
        Une veille technologique permet d’être informé en continu sur les évolutions et les évènements liés à un sujet donné.
         Cette veille peut se faire au moyen de différents outils, dans le but d’agréger plusieurs sources d’informations et de les tenir à jour.<br/><br/>

        J’ai choisi de me tenir regulièrement informé au sujet de différentes technologies de programmation web car cela fait partie des outils que j'utilise et que je serai amené à utiliser à l'avenir.

        VueJS, React, Laravel, Angular sont les principales technologies qui m'intérressent. Ce sont des framework et des librairies très efficaces et de plus en plus utilisés dans le monde professionnel.
        <br/><br/>

        Pour effectuer ma veille j’ai utilisé des outils comme Feedly, Twitter ou même tout simplement les newsletter de sites comme medium.
        Feedly est un agrégateur de flux RSS, c'est-à-dire qu'il récupère les nouveaux contenus en provenance des sites que l'on souhaite suivre. Voici quelques articles que j'ai pu reccueillir grâce à cet outil :
        <br/>
        <img src="../assets/img/article_veille.png">
        <br/>
        Comme vous pouvez le voir dans cet aperçu cette veille me permet d'être tenu informé de toutes les actualités liées aux technologies qui m'interresent
        et d'apprendre de nouvelles méthodes d'utilisation.
        <br/>
        <br/>

      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "veille"
}
</script>

<style scoped>

</style>