<template>
  <div class="competence contain">
    <div class="flex flex-col md:flex-row">

      <div class="main-content flex-1 mt-12 md:mt-2 pb-24 md:pb-5">



        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl p-5">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <img src="../assets/img/vuejslogo.png" width="60">
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">Framework</h5>
                  <h3 class="font-bold text-3xl">Vue JS <span class="text-green-500"><i class="fas fa-caret-up"></i></span></h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl p-5">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <img src="../assets/img/react.svg" width="60">
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">Librairie</h5>
                  <h3 class="font-bold text-3xl">React <span class="text-blue-600"><i class="fas fa-exchange-alt"></i></span></h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl p-5">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <img src="../assets/img/javascript_logo.png" width="60">
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">Langage</h5>
                  <h3 class="font-bold text-3xl">Java Script <span class="text-yellow-600"><i class="fas fa-caret-up"></i></span></h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl p-5">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <img src="../assets/img/PHP-logo.svg" width="100">
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600"> Langage</h5>
                  <h3 class="font-bold text-3xl">Php</h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl p-5">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <img src="../assets/img/CSS3.png" width="40">
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">Langage</h5>
                  <h3 class="font-bold text-3xl">Css</h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
          <div class="w-full md:w-1/2 xl:w-1/3 p-6">
            <!--Metric Card-->
            <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl p-5">
              <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                  <img src="../assets/img/laravel.jpg" class="rounded" width="50">
                </div>
                <div class="flex-1 text-right md:text-center">
                  <h5 class="font-bold uppercase text-gray-600">Framework</h5>
                  <h3 class="font-bold text-3xl">Laravel <span class="text-red-500"><i class="fas fa-caret-up"></i></span></h3>
                </div>
              </div>
            </div>
            <!--/Metric Card-->
          </div>
        </div>
      </div>
    </div>

    <div class="w-full md:w-1/2 xl:w-1/3 p-6" style="margin: auto">
      <div class="bg-gradient-to-b from-gray-600 opacity-90 to-gray-300 border-b-4 border-gray-600 rounded-lg shadow-xl cv">
        <div class="flex flex-row items-center">
          <div class="flex-1 text-right md:text-center">
            <h3 class="font-bold text-3xl mt-2 titleCv">Mon CV</h3>
            <div class="link">
              <a href="https://cv.carlotti-toussaint.com/" target="_blank" class="mr-2 link1">HTML</a>
              <a :href="publicPath+`CV.pdf`" class="ml-2 link1" download="CV_Carlotti">PDF</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  data(){
    return{
      publicPath: process.env.BASE_URL
    }
  },
  mounted() {
    console.log(this.publicPath)
  }
}
</script>

<style scoped>

.cv{
  transition: 0.5s;
  min-height: 100px;
  padding-top: 20px;
}
.cv:hover{
  padding-top: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.titleCv{
  transition: 0.5s;
}
.link{
  transition: 0.5s;
  margin-top: -10px;
  font-weight: bold;
  font-size: large;
  color: transparent;

}
.link1{
  padding: 3px;
  border-radius: 2px;
  transition: 0.5s;
  border-bottom: 2px solid transparent;

}
.link1:hover{
  border-bottom: 2px solid black;
}

.cv:hover .link {
  color: black;
}

.cv:hover .titleCv {
  color: transparent;

}

.button-up,
.button-down {
  position: relative;
  padding: 5px;
  margin: 30px auto;
  background: #000;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.button-down:hover {
  transform: translate3d(0, 10px, 0);
}
.triangle-bottom:hover {
  transform: translate3d(0, 10px, 0);
}

.button-up:hover {
  transform: translate3d(0, -10px, 0);
}

.button-up::after,
.button-down::after {
  content: "";
  position: absolute;
  left: 17px;
  z-index: 11;
  display: block;
  width: 25px;
  height: 25px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.button-up::after {
  top: 20px;
  transform: rotate(45deg);
}

.button-down::after {
  top: 10px;
  transform: rotate(225deg);
}

.arrow-right,
.arrow-left,
.long-arrow-right,
.long-arrow-left{
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
}
.arrow-right,
.long-arrow-right{
  transform: rotate(135deg);
}

.arrow-left,
.long-arrow-left{
  transform: rotate(-45deg);
}
.long-arrow-right::after,
.long-arrow-left::after{
  content: "";
  display: block;
  width: 2px;
  height: 45px;
  background-color: black;
  transform: rotate(-45deg) translate(15px, 4px);
  left: 0;
  top: 0;
}

.triangle-left,
.triangle-right,
.triangle-top,
.triangle-bottom{
  width: 0;
  height: 0;
  margin: 30px auto;
}
.triangle-left,
.triangle-right{
  border-top:
      18px solid transparent;
  border-bottom:
      18px solid transparent;


}
.triangle-top,
.triangle-bottom{
  border-left:
      18px solid transparent;
  border-right:
      18px solid transparent;


}

.triangle-right{
  border-left:
      30px solid black;
}
.triangle-left{
  border-right:
      30px solid black;
}
.triangle-top{
  border-bottom:
      30px solid black;
}
.triangle-bottom{
  border-top:
      30px solid black;
}

</style>
