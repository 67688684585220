<template>
  <div class="project contain">
    <div class="container my-2 mx-auto md:px-8">
      <h1 class="text-3xl font-bold text-gray-600 text-center mb-6">Stage de première année</h1>
      <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-center mt-12 mb-8">

        <!-- Card -->
        <div class="shadow-lg border rounded-xl p-10 text-center z-0 sideCard marginS1S" >
            <a class="text-2xl font-bold" href="https://docs.google.com/document/d/1c0zLT3xkg_syCAFtS2LD0DomRqK1F7muIqjpfDFRhz0/edit" target="_blank" >Refonte Design
              <img src="../assets/img/design.png" class="rounded">
            </a>
            <p class="ml-2 mt-10 text-lg text-justify text">
              Retravailler le design d’une des pages web du site de la S.I.T.E.C, plus précisément un tableau de tarif présentant leurs offres.
              <br/>
              Ce travail a été réalisé grâce a wordpress.
            </p>
        </div>

        <!-- Card -->
        <div class="middleCard shadow-lg border rounded-xl p-10 text-center bg-white z-10 marginS1M" >
            <a href="https://gestion-pro-entite.carlotti-toussaint.com/" target="_blank" class="text-2xl font-bold">Gestion d'informations personnelles d'organisation
              <img src="../assets/img/formulaire.png" >
            </a>
            <p class="ml-2 mt-10 text-lg text-justify text">
              Création d'une petite application permettant d'enregistrer des documents et des informations concernant des organisations.
              <br/>
              Le but de cette application était de gérer ses informations afin d'avoir une vue d'ensemble sur chaque organisations.</p>
            <div class="h-10"></div>
        </div>
        <!-- Card -->
        <div class="shadow-lg border rounded-xl p-10 text-center z-0 sideCard marginS1S">
              <a href="https://docs.google.com/document/d/1c0zLT3xkg_syCAFtS2LD0DomRqK1F7muIqjpfDFRhz0/edit" class="text-2xl font-bold " target="_blank">Script php
                <img src="../assets/img/script.jpg" class="rounded mt-2">
              </a>


            <p class="ml-2 mt-10 text-lg text-justify text">
              Réalisation d'un script php permettant d’automatiser le rangement des fichiers de relevés de consommation de l'entreprise SITEC.

            </p>
        </div>

      </div>
      <div class="w-44" style="margin: 10px auto auto;">
        <a class="text-purple-600 text-center mt-20"
           href="https://docs.google.com/document/d/1c0zLT3xkg_syCAFtS2LD0DomRqK1F7muIqjpfDFRhz0"
           target="_blank">Mon rapport d'activité</a>
      </div>


      <div class="border-t mt-32"></div>


      <h1 class="text-3xl font-bold text-gray-600 text-center mb-6 mt-10">Stage de deuxième année</h1>

      <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-center mt-12 mb-8 ">
        <!-- Card -->
        <div class="shadow-lg border rounded-xl p-10 text-center z-0 middleCard marginS2 w-5/6" >
          <a class="text-2xl font-bold" href="https://gestion-entite.carlotti-toussaint.com/" target="_blank" >Développement d'une interface de référencement d'organisation
            <img src="../assets/img/menuEntite.png" class="rounded">
          </a>
          <p class="ml-2 mt-10 text-lg text-justify text">
            Développement d'un site web dans lequel n’importe quelle organisation pouvait
            s’enregistrer afin que les visiteurs du site puissent trouver des informations
            et une vue d'ensemble rapide de chacune d’entre elles.
          </p>
        </div>

        <!-- Card -->
        <div class="shadow-lg border rounded-xl p-10 text-center z-0 middleCard marginS2">
          <a href="https://gestion-client.carlotti-toussaint.com/" class="text-2xl font-bold " target="_blank">Développement d'une interface de gestion client
            <img src="../assets/img/menuClient.png" class="rounded mt-2">
          </a>

          <p class="ml-2 mt-10 text-lg text-justify text">
            Réalisation d'une application web permettant de gérer tous les clients, factures et devis d’une entreprise.
            <br> <br>
            liens vers mes dépots git : <br>
            <a href="https://gitlab.com/toussaintcarlotti/backend_vuejs" target="_blank" class="font-bold hover:text-blue-700">Backend</a>
            &
            <a href="https://gitlab.com/toussaintcarlotti/frontend_vuejs" target="_blank" class="font-bold hover:text-blue-700">Frontend</a>
          </p>
        </div>
      </div>

      <div class="w-44" style="margin: 10px auto auto;">
        <a class="text-purple-600 text-center mt-20" href="https://docs.google.com/document/d/1z2Mu33IMiOQonyCbbs-5EenjEK3rEGn7MXPeWWQOKKo/edit"
         target="_blank">Mon rapport d'activité</a>
      </div>

      <div class="border-t mt-20"></div>
      <h1 class="text-3xl font-bold text-gray-600 text-center mb-6 mt-10">En formation</h1>

      <!-- Card -->
      <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-center mt-12 mb-8 ">
        <div class="shadow-lg border rounded-xl p-10 text-center z-0 middleCard marginS2S w-5/6" style="width: 1000px; height: 750px" >
          <a class="text-2xl font-bold" href="https://geststages.carlotti-toussaint.com/" target="_blank" >Développement d'une interface de gestion de stage
            <img src="../assets/img/stagiaire_geststages.png" class="mt-10" alt="">
          </a>
          <p class="ml-2 mt-10 text-lg text-justify text">
            Développement d'une application web qui permet de gérer les stages des élèves du lycée Laetitia Bonaparte.
            <br> <br>
            liens vers le dépot git :
            <a href="https://gitlab.com/toussaintcarlotti/geststages_react_carlotti" target="_blank" class="font-bold hover:text-blue-700">Dépot</a>
          </p>
        </div>
      </div>
  </div>
</div>

</template>

<script>
export default {
name: "project"
}
</script>

<style scoped>

.marginS1M{
  margin-left: -10px;
  margin-right: -10px;
}
.marginS2{
  margin: 20px;
}
.sideCard, .middleCard{
  transition: 0.5s;
}
.middleCard{
  width: 33%;
  height: 600px;

}
.sideCard{
  margin-top: auto;
  margin-bottom: auto;
  width: 25%;
  height: 500px;
}

.middleCard:hover{
  transform: scale(1.03);
  box-shadow:0 0 20px 1px rgb(178, 176, 222);
}

.sideCard:hover{
  transform: scale(1.03);
  box-shadow:0 0 20px 1px rgb(178, 176, 222);
}



@media screen and (max-width: 1536px) {
  .middleCard{
    width: 500px;
    height: 600px;
  }
  .sideCard{
    margin-top: auto;
    margin-bottom: auto;
    width: 360px;
    height: 500px;
  }

}
@media screen and (max-width: 1280px) {
  .middleCard, .sideCard {
    width: 47%;
    height: 550px;
    margin: 10px;
  }

}

@media screen and (max-width: 1024px) {
  .sideCard, .middleCard{
    margin: 10px;
    min-height: 630px;
    width: 96%;
  }

}



</style>