<template>
  <div class="parcours contain">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic' rel='stylesheet' type='text/css'>

    <!-- The Timeline -->

    <ul class="timeline">
      <li>
        <div class="direction-l dl1">
          <div class="flag-wrapper">
            <span class="flag">Lycée Fesch</span>
            <span class="time-wrapper"><span class="time">2015 - 2018</span></span>
          </div>
          <div class="desc">J'ai éffectué mes 3 années de préparation du baccalauréat au lycée Fesch situé dans la villle d'Ajaccio.</div>
        </div>
      </li>

      <li>
        <div class="direction-r dr1">
          <div class="flag-wrapper">
            <span class="flag">Baccalauréat</span>
            <span class="time-wrapper"><span class="time">2018</span></span>
          </div>
          <div class="desc">Obtention d'un Baccalauréat Sciences et Sechnologies du Sanagement et de la Sestion.</div>
        </div>
      </li>
      <li>
        <div class="direction-l dl2">
          <div class="flag-wrapper">
            <span class="flag">Licence SPI</span>
            <span class="time-wrapper"><span class="time">2018 - 2019</span></span>
          </div>
          <div class="desc">J'ai éffectué une année de Licence en science pour l'ingenieur avant de me réorienter vers des études plus centrées sur l'informatique.</div>
        </div>
      </li>
      <li>
        <div class="direction-r dr2">
          <div class="flag-wrapper">
            <span class="flag">BTS SIO</span>
            <span class="time-wrapper"><span class="time">2019 - présent</span></span>
          </div>
          <div class="desc">Je suis actuellement en deuxième année de BTS Services informatiques aux organisations.</div>
        </div>
      </li>
      <li>
        <div class="direction-l dl3">
          <div class="flag-wrapper">
            <span class="flag">Stage SITEC</span>
            <span class="time-wrapper"><span class="time">2019 - 2020</span></span>
          </div>
          <div class="desc">J'ai réalisé un stage dans l'entreprise SITEC qui est spécialisée dans le domaine de l'informatique (réseau, développement, hébergement). </div>
        </div>
      </li>
      <li>
        <div class="direction-r dr3">
          <div class="flag-wrapper">
            <span class="flag">Stage IT Consulting</span>
            <span class="time-wrapper"><span class="time">2020 - 2021</span></span>
          </div>
          <div class="desc">Durant ma deuxième année de BTS SIO j'ai réalisé un stage dans l'entreprise IT consulting qui est aussi spécialisée dans le domaine de l'informatique.</div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
import '../assets/css/parcours.css'
export default {
name: "Parcours"
}
</script>



